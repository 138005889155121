
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";

@Component({})
export default class ChangeSuccess extends Vue {
  get breadData() {
    return [
      { name: this.$t("v210831.store-management"), path: "/management/apply-list" },
      { name: this.$t("v210831.opening-result") }
    ];
  }
}
